p {
	color: 7978;
}
.pd-r40{
	padding-right: 60px;
}
.pd-l40{
	padding-left: 60px;
}
.first_box p, .scnd_box p{
	font-size: 16px;
}
.ftr-text p{
	margin-bottom: 0px;
	font-size: 15px;
}
.scnd_box{
	display: inline-block;
}
.addr_sec p{
	margin-bottom: 0px;
	display: inline-block;
}
.addr_sec p a{
	color: #5e6e82;
}
.addr_sec ul{
	padding: 0px;
	margin: 0px;
}
.addr_sec ul li {
	list-style: none;
	position: relative;
	padding-left: 30px;
	margin-bottom: 4px;
}
.addr_sec ul li span {
	position: absolute;
	left: 0;
	border: 1px solid #5e6e82;
	width: 22px;
	height: 22px;
	text-align: center;
	border-radius: 50px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	top: 2px;
}
.addr_sec_first ul li span{
	top: 2px;
}
.addr_sec ul li span .fas {
	font-size: 14px;
}
.addr_sec_first ul{
	text-align: center;
}
.addr_sec_first ul li{
	text-align: left;
	display: inline-block;
}
.plr-10 .row{
	padding-left: 10px;
	padding-right: 10px;
}

#grnadLodgeHeaderModal .form-control {
	margin-bottom: 10px;
}
#grnadLodgeHeaderModal .formError {
	position: absolute;
	margin-top: -10px;
	color: red;
	font-size: 10px;
}
#grnadLodgeHeaderModal .datetime .form-control {
	width: 100%;
	border: 1px solid #d8e2ef;
	height: 36px;
	border-radius: 5px;
	color: #344050;
	font-size: 1rem;
	font-weight: 400;
}

#lodgeHeaderModal .switch__parent .switch, #grnadLodgeHeaderModal .switch__parent .switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch__parent .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.switch__parent .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

#lodgeHeaderModal .switch__parent .slider:before, #grnadLodgeHeaderModal .switch__parent .slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 14px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch__parent input:checked + .slider {
  background-color: #f3681a ;
}

.switch__parent input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

.switch__parent input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.switch__parent .slider.round {
  border-radius: 34px;
}

.switch__parent .slider.round:before {
  border-radius: 50%;
}

.modal__chekbox{
	display: initial;
}

.modal__chekbox .inputRadio{
	cursor: pointer;
	display: ruby-base;
	align-items: center;
	margin-right: 10px;
}

.modal__chekbox .inputRadio input{
	position: unset;
	margin-left: unset;
}
.stus_grp label{
	margin-right: 12px;
}

.loggedInRecognition .SpanText {
	position: relative ;
	font-family: "Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
	font-size:'10pt';
}
.loggedInRecognition .admin_dropdown {
	position:relative; 
	font-family:"Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
	font-size:10pt;
	margin-left:5px;
}

.loggedInRecognition .recNotRec {
	margin:0px;
	text-align:right
}

.lodgeSorting {
	color: #337ab7;
	font-weight: 600;
	cursor: pointer !important;
}

@media screen and (max-width: 767px) {
	.first_box.text-right, .text-left.first_box{
		text-align: center!important;
		padding: 0px;
	}
	.text-right.col-md-6{
		text-align: center!important;
	}
	.scnd_box {
	    display: block;
	    text-align: center;
	    padding: 0px;
	}
	.footr_dash .pd-r40{
		padding-right: 2px;
	}
	.footr_dash .pd-l40{
		padding-left: 2px;
	}
}

@media screen and (max-width: 575px) {
	.addr_sec_first ul{
		text-align: center;
	}
	.addr_sec_first ul li{
		display: block;
		text-align: left;
	}
	.breadcrumb-flex select {
    	width: 100% !important;
	}
	.mb_address_section{
		justify-content: left !important;
	}
}